.app-wrapper {
  justify-content: center;
  width: 100%;
  z-index: 10;
  max-width: 700px;
  margin: auto;
  margin-top: 5em;
  box-shadow: 15px 15px 20px rgba(1, 1, 1, 0.3);
  height: 80%;
}

.app-wrapper button {
  cursor: pointer;
  z-index: 10;
}

.site-description {
  font-size: 15pt;
  color: var(--alto);
  line-height: 1.7em;
  margin-bottom: 0.5em;
}

.opensea-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--persian-green);
}

.opensea-link span {
  margin-right: 7px;
  text-decoration: underline;
}

.opensea-link svg {
  width: 14px;
}


@media screen and (max-width: 700px) {
  .app-wrapper{
    box-shadow: none;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
  }
}
