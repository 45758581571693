.room-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.room-metadata {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  color: var(--persian-green);
  font-size: 20pt;
}


.room-metadata svg {
  width: 20px;
  cursor: pointer;
}


@media screen and (max-width: 700px) {
  .room-wrapper {
    max-height: 100vh;
    /* mobile viewport bug fix */
    max-height: -webkit-fill-available;
    margin: auto;
  }
}