.messages-container {
  color: white;
  display: flex;
  flex-direction: column;
  height: 90%;
  position: relative;
}

.messages-wrapper {
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
}

.message-input-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 45px;
  max-height: 45px;
  bottom: 5px;
  width: 100%;
  margin-top: 25px;
  border-radius: 10px;
  /* overflow: hidden; */
  transition: all ease 0.3s;
}

.message-input {
  background: none;
  border: none;
  flex: 1;
  height: 45px;
  max-width: calc(100% - 50px);
  padding-left: 13px;
  color: white;
  font-size: 12pt;
  border-radius: 10px;
  outline-color: var(--persian-green);
}

.message-input-button {
  position: absolute;
  right: 0;
  width: 50px;
  height: 100%;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-input-button svg {
  stroke: var(--persian-green);
  width: 20px;
  margin-right: 3px;
  transform: translateY(1px);
}

@media screen and (max-width: 700px) {
  .messages-container {
    flex: 1;
    /* overflow: hidden; */
  }
  .messages-wrapper {
    flex: 4;
    padding-bottom: 60px;
  }
  .message-input-wrapper {
    z-index: 2;
    flex: 1;
    min-height: 45px;
    position: absolute;
    bottom: 15px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.4);
    transition: all ease 0.3s;
  }

  .messages-container {
    overflow-x: visible;
  }

}