.room-info-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
}

.room-name {
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 10px;  
  text-wrap: nowrap;
  color: white;
  color: var(--persian-green);
}

.room-description {
  font-size: 12pt;
  font-weight: 300;
  color: var(--alto);
}

.room-qr-code-wrapper {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.room-qr-code {
  min-width: 120px;
  box-shadow: 0px 1px 10px rgba(1, 1, 1, 0.15);
  margin-right: 20px;
}

.invitation-text-wrapper {
  font-weight: 300;
  color: var(--alto);
  margin-bottom: 10px;
  display: block;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--east-bay);
  margin: 20px 0;
}

.qr-code-shareable-button {
  width: 100%;
  border-radius: 7px;
  height: 30px;
  font-weight: bold;
  background: var(--east-bay);
  color: var(--glandis);
  border: 1px solid var(--ebony-clay);
  cursor: pointer;
  transition: all ease 0.3s;
}

.qr-code-shareable-button:active {
  transform: scale(0.95);
}


@media screen and (min-width: 700px) {
  .room-info-wrapper {
    max-width: 500px;
  }
}