.message-wrapper {
  margin-bottom: 25px;
  position: relative;
}

.message-wrapper.pending * {
  opacity: 0.5 !important;
}

.message-metadata-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}

.message-username {
  margin-right: 10px;
  font-size: 1.1em;
  margin-bottom: 3px;
  color: var(--glandis);
}

.message-timestamp {
  font-size: 12px;
  color: var(--manatee);
  padding-right: 10px;
}

.message-message {
  font-weight: 400;
  padding-left: 1px;
  display: block;
  max-width: 85%;
}

.message-likes-wrapper {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.message-likes-wrapper svg {
  width: 15px;
  cursor: pointer;
}

.message-likes-wrapper svg.liked{
  color: var(--persian-green);
}

.message-like-count {
  font-size: 12px;
  display: block;
  margin-right: 7px;
  transform: translateY(2px);
}

.message-audio-display {
  max-height: 40px;
  max-width: 90%;
  background-color: rgba(0,0,0,0.05);
  border-radius: 5px;
  padding: 1px 15px;
  margin-top: 5px;
}

.message-audio-display.hidden {
  display: none;
}

.message-audio-display div {
  max-height: 100%;
}

/* create media query for mobile devices */
@media screen and (max-width: 700px) {
  .message-audio-display {
    max-width: 80%;
  }
}  