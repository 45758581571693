.fake-message-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.fake-message-username {
  height: 40px;
}

.fake-message-text {
  height: 40px;
}