.audio-display {
  position: absolute;
  top: 50%;
  left: 40px;
  width: 83%;
  height: 85%;
  transform: translateY(-50%);
}

.audio-display div {
  max-height: 100%;
}

.delete-audio {
  margin-left: 10px;
  transform: translateY(1px);
  width: 20px;
  color: var(--vivid-tangerine);
  cursor: pointer;
}

.progress-display {
  height: 5px;
  background: var(--dark-space-cadet);
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 85%;
  height: 100%;
  transform: translateY(-50%);
}

.progress-display::after {
  animation: progress 15s linear;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 70%;
  background: var(--persian-green);
  border-radius: 3px;
}

/* animation for the progress bar that slides from left to right for 15 seconds */
@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}