@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

:root {
  /* color scheme */
  --ebony-clay: #212434;
  --east-bay: #2d3142;
  --glandis: #ffd082;
  --vivid-tangerine: #ff8282;
  --silver: #bbbbbb;
  --alto: #d7d7d7;
  --persian-green: #33b3a0;

  --illuminating-emerald: #2b927b;
  --space-cadet: #262B3F;
  --dark-space-cadet: #1e2337;
  --manatee: #9CA1B1;
}

* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

#root {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  background: var(--ebony-clay);
  max-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-bounce {
  transition: all ease 0.3s;
}

.hover-bounce:hover {
  transform: scale(1.005);
}

.hover-bounce:active {
  transform: scale(1);
}

.shadow-hover {
  box-shadow: 0px 1px 5px rgba(1, 1, 1, 0.15);
  transition: all ease 0.3s;
}

.shadow-hover:hover {
  box-shadow: 0px 0px 10px rgba(1, 1, 1, 0.15);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-skeleton {
  min-width: 150px;
  max-width: 200px;
  background-color: var(--persian-green) !important;
}

.fade-in {
  animation: fade-in 0.3s;
}

@keyframes fade-in {
  0% { 
    opacity: 0; 
    transform: scale(0.95);
  }
  100% { 
    opacity: 1; 
    transform: scale(1);
  }
}

.app-wrapper::before {
  content: "";
  background-image: url("./assets/beanstalk-dark.webp");
  opacity: 0.2; /* Adjust this value to change the transparency */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;  
}

@media screen and (max-width: 700px) {
  #root, body, .app-wrapper {
    max-height: 100vh;
    /* mobile viewport bug fix */
    max-height: -webkit-fill-available;
  }  

  body, #root {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #3b425e var(--dark-space-cadet);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #3b425e var(--dark-space-cadet);
}